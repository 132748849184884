* {
  margin: 0;
  padding: 0;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.row>* {
  
  /* padding-right: 0 !important; 
  padding-left: 0 !important; */
}

.form-outline .form-control {
  background-color: white !important;
}
.form-label {

}

.fas .fa-ellipsis-v::before {
 
} 

.dropend .dropdown-toggle::after {
  vertical-align: 0;
}
.no-icon > .dropend .dropdown-toggle::after {
  display: none !important;
  margin-left: .255em;
  vertical-align: .255em;
  content: "";
  border-top: .3em solid transparent;
  border-right: 0;
  border-bottom: .3em solid transparent;
  border-left: .3em solid;
}